<!--
 * @Description: 通用记账
 * @Author: 琢磨先生
 * @Date: 2022-11-15 15:10:48
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-07 17:47:55
-->

<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="房间">
        <el-cascader
          :options="huxing_list"
          v-model="query.storeIds"
          clearable
          style="width: 300px"
          filterable
        ></el-cascader>
      </el-form-item>
      <!-- <el-form-item label="项目">
          <el-select
            v-model="query.typesIds"
            multiple
            clearable
            style="width: 300px"
          >
            <el-option
              v-for="item in expenseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
      <el-form-item label="类型">
        <el-select v-model="query.order_type" clearable style="width: 100px">
          <el-option label="收入" :value="0"></el-option>
          <el-option label="支出" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="query.dates"
          type="daterange"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div>
      <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
        >新增</el-button
      >
    </div>
  </el-card>
  <el-card class="box data">
    <el-table
      :data="tableData.data"
      @sort-change="onSort"
      border
      row-key="id"
      @selection-change="tableSelectionChange"
    >
      <el-table-column
        label
        type="selection"
        reserve-selection
      ></el-table-column>
      <el-table-column
        prop="in_or_out_text"
        label="类型"
        width="140"
      ></el-table-column>
      <el-table-column
        prop="generalType.name"
        label="项目"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="store.house_no"
        label="关联房号"
        width="200"
      ></el-table-column>

      <el-table-column label="金额(元)" width="140">
        <template #default="scope"
          >￥{{ scope.row.decimal_total_amount }}</template
        >
      </el-table-column>
      <el-table-column label="日期" prop="date" width="120"></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        min-width="240"
      ></el-table-column>
      <el-table-column
        prop="emp.chs_name"
        label="创建人"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="create_at"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="140">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            v-if="scope.row.status === 0 || scope.row.status === 4"
            >修改</el-button
          >
          <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>

  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="800px"
    top="10vh"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    draggable
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
    >
      <el-form-item label="类型" prop="in_or_out">
        <el-radio-group v-model="form.in_or_out" @change="inOrOutChange">
          <el-radio :label="0">收入</el-radio>
          <el-radio :label="1">支出</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="项目" prop="type_id">
        <el-select v-model="form.type_id">
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联房间" prop="storeIds">
        <el-cascader
          :options="huxing_list"
          v-model="form.storeIds"
          style="width: 300px"
          filterable
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="金额(元)" prop="decimal_total_amount">
        <el-col :span="10">
          <el-input v-model="form.decimal_total_amount" placeholder></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="日期" prop="date">
        <el-date-picker
          v-model="form.date"
          type="date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :disabled-date="(date) => date > new Date()"
          placeholder
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="备注">
        <el-col :span="16">
          <el-input
            v-model="form.remark"
            placeholder
            type="textarea"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="凭证图片">
        <upload-gallery
          :pictures="form.pic_urls"
          @change="uploadChange"
        ></upload-gallery>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>
  
  <script>
import UploadGallery from "../../psd/upload_gallery.vue";
import huxing_api from "@/http/huxing_api";
import payment_api from "@/http/payment_api";
import tallybook_api from "@/http/tallybook_api";

export default {
  components: {
    UploadGallery,
  },
  data() {
    return {
      loading: false,
      visibleDialog: false,
      saving: false,
      title: "",
      huxing_list: [],
      //
      payment_terms: [],
      //项目类型列表
      typeList: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //选中的数据
      selection_list: [],
      form: {
        in_or_out: 0,
      },

      rules: {
        in_or_out: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        decimal_total_amount: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        type_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        decimal_ratio: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],

        date: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    tallybook_api.get_usable_types().then((res) => {
      if (res.code == 0) {
        this.type_list = res.data;
        this.typeList = this.type_list.filter(
          (x) => x.in_or_out == this.form.in_or_out
        );
      }
    });
    payment_api.get_terms().then((res) => {
      if (res.code == 0) {
        this.payment_terms = res.data;
      }
    });
    huxing_api.get_cascader().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            value: x.id,
            label: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              value: item.id,
              label: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 选中更改
     */
    tableSelectionChange(nodes) {
      this.selection_list = nodes;
    },

    /**
     * 加载统计数据
     */
    loadData() {
      this.$http.post("seller/v1/tallyBook", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 新增、修改
     * @param {*} item
     */
    onEdit(item) {
      this.title = item ? "修改" : "记一笔";
      this.form = {};
      this.form = Object.assign(
        {
          in_or_out: 0,
        },
        item
      );
      this.visibleDialog = true;
    },
    /**
     * 删除
     * @param {*} item
     */
     onDelete(item) {
      this.$http
        .get("seller/v1/tallyBook/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },
    /**
     * 类型切换
     */
    inOrOutChange() {
      this.typeList = this.type_list.filter(
        (x) => x.in_or_out == this.form.in_or_out
      );
      this.form.type_id = "";
    },
    /**
     * 图片上传回调
     * @param {*} urls
     */
    uploadChange(urls) {
      this.form.pic_urls = urls;
    },
    /**
     * 保存
     */
    onSubmit() {
      if (this.form.storeIds && this.form.storeIds.length > 0) {
        this.form.store_id = this.form.storeIds[this.form.storeIds.length - 1];
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/tallyBook/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>